////////// BACKGROUND COLOR //////////
.bg-primaryColor {
  background-color: rgb(250, 250, 250);
}
.bg-secondaryColor {
  background-color: rgb(250, 250, 250);
}
.bg-thirdColor {
  background-color: rgb(250, 250, 250);
}
.bg-fourthColor {
  background-color: rgb(34, 34, 34);
}
.bg-fifthColor {
  background-color: rgb(48, 48, 48);
}
.bg-sixthColor {
  background-color: rgb(34, 34, 34);
}
.bg-seventhColor {
  background-color: rgb(34, 34, 34);
}
